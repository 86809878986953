































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CalcFileUpload from '../components/calculator/CalcFileUpload.vue';
import CalcProperties from '@/components/calculator/CalcProperties.vue';
import CalcOffer from '@/components/calculator/CalcOffer.vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import CalcShipping from '@/components/calculator/CalcShipping.vue';
import CalcOrder from '@/components/calculator/CalcOrder.vue';
import CalcExportLastech from '@/components/calculator/CalcExportLastech.vue';

interface BexioTypes {
  bexio_acc_token: string;
  bexio_org: string;
}

interface SelectedCustomersTypes {
  customer_id: number;
  sub_customer_id: number;
}

@Component({
  components: {
    calcfileupload: CalcFileUpload,
    calcproperties: CalcProperties,
    calcoffer: CalcOffer,
    calcshipping: CalcShipping,
    calcorder: CalcOrder,
    calcexport: CalcExportLastech
  }
})
export default class Calculator extends Vue {
  offer_name: string = '';
  offset: string = '';
  vm = this;
  steps = this.defineSteps();

  defineSteps() {
    let vm = this;

    let steps = [];

    steps.push({
      name: 'Dateien hochladen',
      component: 'calcfileupload',
      eventHandling: {}
    });
    steps.push({
      name: 'Eigenschaften',
      component: 'calcproperties',
      eventHandling: {}
    });
    steps.push({
      name: 'Produktionszeit',
      component: 'calcshipping',
      props: {
        get shipping() {
          return vm.selectedShipping;
        }
      }
    });

    steps.push({
      name: 'Offerte',
      component: 'calcoffer',
      eventHandling: {}
    });

    // if (this.hasManufacturerRights()) {
    //   steps.push({
    //     name: 'Export (ERP)',
    //     component: 'calcexport',
    //     eventHandling: {}
    //   });
    // }

    return steps;
  }

  get selectedShipping() {
    return this.$store.getters.currentOfferShipping;
  }

  get partsImages() {
    return this.$store.getters.uploadedPartsWithImages;
  }

  get bexioAuthentication() {
    return this.$store.getters.bexioAuthentication;
  }

  backToOfferOverview() {
    this.$store.commit('resetOffer');
    this.$router.push({ path: '/' });
  }

  deleteOffer() {
    this.$store.dispatch('deleteCurrentOffer');
    this.$store.commit('resetOffer');
  }

  get currentOfferName() {
    return this.$store.getters.getFormattedOfferId + ' - ' + this.$store.getters.currentOfferName;
  }

  get isNavDrawerOpen() {
    return this.$store.getters.isNavDrawerOpen;
  }

  @Watch('isNavDrawerOpen')
  onNavDrawerChange(val: boolean, old_val: boolean) {
    if (val) {
      this.offset = `left:${this.calcOffset()}px`;
    } else {
      this.offset = '';
    }
  }

  get currentStep() {
    return this.$store.getters.currentStepCalcDialog;
  }

  calcOffset() {
    let calc_container: Element = this.$refs.calc_container as Element;
    return calc_container.getBoundingClientRect().left;
  }

  updateStep(step_number: number) {
    this.$store.commit('setCurrentStepCalcDialog', step_number);
  }

  get isOfferActive() {
    return this.$store.getters.isOfferActive;
  }

  createOffer() {
    axios
      .post(`${BACKEND_URL}/create_offer`, {
        offer_name: this.offer_name
        // is_intern: this.internOffer
      })
      .then(response => {
        if (response.status === 200) {
          if ('offer' in response.data) {
            this.$store.commit('setOffer', response.data.offer);
          }
        }
      });
  }

  isCustomer() {
    return this.$store.getters.isCustomer;
  }

  hasManufacturerRights() {
    return this.$store.getters.hasManufacturerRights;
  }

  mounted() {
    if (this.isNavDrawerOpen) {
      this.offset = `left:${this.calcOffset()}px`;
    }
  }
}
