







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';
import CalcOfferItemManufacturer from '@/components/calculator/CalcOfferItemManufacturer.vue';
import { CustomerPartEntry } from '@/customer/part_details';
import CalcOfferItemCustomer from '@/components/calculator/CalcOfferItemCustomer.vue';

@Component({
  components: {
    calcofferitemmanufacturer: CalcOfferItemManufacturer,
    calcofferitemcustomer: CalcOfferItemCustomer
  }
})
export default class CalcOffer extends Vue {
  editedItem: any = {};
  BACKEND_URL = BACKEND_URL;
  uuidComments: any = {};
  dialogAfterVerification = false;

  get parts(): CustomerPartEntry[] {
    return Object.values(this.$store.getters.uploadedParts);
  }

  get storeOfferSets() {
    return this.$store.getters.sets;
  }

  offerSelectionChanged(uuid: string, selectedIndex: number, comment: string) {
    this.uuidComments[uuid] = comment;
    this.$store.commit('updateCurrentOfferSelection', {
      uuid,
      selectedIndex,
      comment
    });
  }

  getPartByUUID(partUUID: string) {
    return this.$store.getters.parts[partUUID];
  }
  getPartNameByUUID(partUUID: string) {
    let fileName = this.$store.getters.parts[partUUID].source_file_name;
    let partName = this.$store.getters.parts[partUUID].features.name;
    return `${fileName} - ${partName}`;
  }
  getSetByUUID(uuid: string) {
    return this.$store.getters.sets[uuid];
  }
  getCountOfSetForPart(partUUID: string, setUUID: string) {
    let currSet = this.$store.getters.sets[setUUID];
    for (let entry of currSet.parts) {
      if (entry.part_uuid == partUUID) {
        return entry.count;
      }
    }
    return -1;
  }

  get hasManufacturerRights() {
    return this.$store.getters.hasManufacturerRights;
  }
}
