


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';
import CalcOfferItemManufacturer from '@/components/calculator/CalcOfferItemManufacturer.vue';
import { CustomerPartEntry } from '@/customer/part_details';
import CalcOfferItemCustomer from '@/components/calculator/CalcOfferItemCustomer.vue';
import ImageViewer from '@/components/util/ImageViewer.vue';

@Component({
  components: {
    zaimagevisualizer: ImageViewer
  }
})
export default class CalcOffer extends Vue {
  dialog = false;
  BACKEND_URL = BACKEND_URL;
  total: number = 0;
  headers: any = [
    {
      text: 'Teilename',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Bild',
      align: 'center',
      sortable: false,
      value: 'visualization_data'
    },
    { text: 'Material', value: 'material' },
    { text: 'Dicke', value: 'thickness' },
    { text: 'Finish', value: 'finish' },
    { text: 'Anzahl', value: 'count', align: 'right' },
    { text: 'Preis', value: 'price', align: 'right' }
  ];
}
