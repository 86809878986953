<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="offer_name"
          label="Anfrage-Name"
          placeholder="Anfrage"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-stepper v-model="currentStep" vertical>
          <v-stepper-step :complete="currentStep > 1" step="1">
            Bauteile definieren
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-container>
              <v-row align="center" justify="center">
                <v-col class="d-flex justify-center" cols="12">
                  <mobile-part @added-part="addedPart">
                    <template v-slot:buttonSlot="{ on, attrs }">
                      <v-btn fab v-bind="attrs" v-on="on" @click="test">
                        <v-icon>
                          add
                        </v-icon>
                      </v-btn>
                    </template>
                  </mobile-part>
                </v-col>
                <v-col cols="12">
                  <v-list v-if="parts.length > 0">
                    <v-list-item
                      v-for="(part, index) in parts"
                      :key="`${part.name}_${index}`"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ part.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="deletePart(index)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <div v-else class="d-flex justify-center">
                    Bitte Bauteile hinzufügen
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>

          <v-stepper-step :complete="currentStep > 2" step="2">
            Lieferbedingungen
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-container>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="deliveryDate"
                    persistent
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="deliveryDateFormatted"
                        label="Gewünschtes Lieferdatum"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="deliveryDate"
                      scrollable
                      locale="de-CH"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(deliveryDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col>
                  <v-select
                    label="Lieferart"
                    v-model="deliveryType"
                    :items="deliveryTypes"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>

          <!--          <v-stepper-step :complete="currentStep > 3" step="3">-->
          <!--            Lieferadresse-->
          <!--          </v-stepper-step>-->

          <!--          <v-stepper-content step="3">-->
          <!--            <v-container>-->
          <!--              <v-row>-->
          <!--                <v-col cols="12">-->
          <!--                  <v-text-field-->
          <!--                    v-model="name_comp"-->
          <!--                    :label="`Name Vorname / Firma ${mandatoryChar()}`"-->
          <!--                  ></v-text-field>-->
          <!--                </v-col>-->
          <!--                <v-col cols="12">-->
          <!--                  <v-text-field-->
          <!--                    v-model="street"-->
          <!--                    :label="`Strasse ${mandatoryChar()}`"-->
          <!--                  ></v-text-field>-->
          <!--                </v-col>-->
          <!--                <v-col cols="12">-->
          <!--                  <v-text-field-->
          <!--                    v-model="city"-->
          <!--                    :label="`PLZ / Ort ${mandatoryChar()}`"-->
          <!--                  ></v-text-field>-->
          <!--                </v-col>-->
          <!--                <v-col cols="12">-->
          <!--                  <v-text-field-->
          <!--                    v-model="phone"-->
          <!--                    :label="`Telefon-Nr ${mandatoryChar()}`"-->
          <!--                  ></v-text-field>-->
          <!--                </v-col>-->
          <!--              </v-row>-->
          <!--            </v-container>-->
          <!--          </v-stepper-content>-->

          <v-stepper-step step="3">
            Abschluss
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  v-for="(check, index) in checks"
                  :key="`check_${index}`"
                >
                  <div class="error--text">
                    {{ check }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-dialog v-model="requestDialog">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        block
                        v-bind="attrs"
                        v-on="on"
                        :disabled="checks.length > 0"
                      >
                        Anfrage absenden
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Anfrage absenden?
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              Bei Absenden der Anfrage erstellt Ihnen die
                              Staffelbach AG ein unverbindliches Angebot.
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="requestDialog = false"
                        >
                          Abbrechen
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="uploadOffer('request')"
                        >
                          Absenden
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-dialog v-model="orderDialog">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        block
                        v-bind="attrs"
                        v-on="on"
                        :disabled="checks.length > 0"
                      >
                        Direkt bestellen
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Bestellung absenden?
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              Bei Bestätigung der Bestellung werden die
                              hochgeladenen Bauteile direkt produziert und nach
                              Aufwand verrechnet
                            </v-col>
                            <v-col cols="12">
                              <v-checkbox v-model="agbAccepted">
                                <template v-slot:label>
                                  <div>
                                    <a
                                      @click.stop
                                      target="_blank"
                                      href="https://www.a-staffelbach.ch/"
                                      >AGB</a
                                    >
                                    akzeptieren
                                  </div>
                                </template>
                              </v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="orderDialog = false"
                        >
                          Abbrechen
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="uploadOffer('order')"
                          :disabled="!agbAccepted"
                        >
                          Absenden
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
        </v-stepper>
      </v-col>
      <v-col cols="12">
        <v-dialog v-model="uploadDialog">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Auftrag wird ausgeführt
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    Die Daten werden nun übermittelt
                  </v-col>
                  <v-col cols="12">
                    <v-list class="overflow-y-auto" max-height="300px">
                      <v-list-item>
                        <v-list-item-icon v-if="offerOrRequestCreated">
                          <v-icon>done</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else>
                          <v-progress-circular
                            :rotate="-90"
                            :size="25"
                            :width="5"
                            color="primary"
                            indeterminate
                          >
                          </v-progress-circular>
                        </v-list-item-icon>
                        <v-list-item-title
                          >{{ typeText }} erstellen</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-for="(part, index) in parts"
                        :key="`${part.name}_${index}`"
                      >
                        <v-list-item-icon v-if="part.uploaded">
                          <v-icon>done</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else>
                          <v-progress-circular
                            :rotate="-90"
                            :size="25"
                            :width="5"
                            color="primary"
                            indeterminate
                          >
                          </v-progress-circular>
                        </v-list-item-icon>
                        <v-list-item-title
                          >{{ part.name }} übermitteln</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" class="subtitle-1" v-if="requestFinalized">
                    <strong
                      >{{ typeText }} wurde erfolgreich übermittelt!</strong
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="finalizeRequestProcess"
                :disabled="!requestFinalized"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-btn
        fab
        fixed
        bottom
        left
        @click="currentStep = Math.max(currentStep - 1, 1)"
      >
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-btn
        fab
        fixed
        bottom
        right
        @click="currentStep = Math.min(currentStep + 1, 4)"
      >
        <v-icon>arrow_forward</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import MobilePart from '@/components/mobile/MobilePart';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
export default {
  name: 'MobileOffer',
  components: { MobilePart },
  data: function() {
    return {
      offer_name: 'Mobile-Anfrage',
      parts: [],
      name_comp: '',
      street: '',
      city: '',
      phone: '',
      offerOrRequestCreated: false,
      offerOrRequestSubmited: false,
      deliveryDate: null,
      deliveryTypes: ['Abholung (ab Werk)'],
      deliveryType: 'Abholung (ab Werk)',
      requestDialog: false,
      orderDialog: false,
      agbAccepted: false,
      uploadDialog: false,
      typeText: '',
      modal: false,
      currentStep: 1
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    test() {
      this.$router.push('#part-dialog');
    },
    addedPart(part) {
      if (part.name.length === 0) {
        part.name = `Bauteil ${this.parts.length + 1}`;
      }
      this.parts.push(part);
    },
    // mandatoryChar() {
    //   if (this.deliveryType === 'Lieferung') {
    //     return '*';
    //   } else {
    //     return '';
    //   }
    // },
    deletePart(index) {
      this.parts.splice(index, 1);
    },
    finalizeRequestProcess() {
      this.uploadDialog = false;
      this.$router.push('/');
    },
    uploadOffer(type) {
      this.orderDialog = false;
      this.requestDialog = false;
      this.uploadDialog = true;

      let allDone = true;

      if (type === 'request') {
        this.typeText = 'Anfrage';
      } else if (type === 'order') {
        this.typeText = 'Auftrag';
      }

      axios
        .post(`${BACKEND_URL}/create_offer`, {
          offer_name: this.offer_name,
          status_code: 'OFFER_MOBILE_REQUEST',
          delivery_info: {
            type: this.typeText,
            delivery_date: this.deliveryDateFormatted,
            delivery_type: this.deliveryType
          },
          additional_info: {
            name_comp: this.name_comp,
            street: this.street,
            city: this.city,
            phone: this.phone,
            type: this.typeText
          }
        })
        .then(async response => {
          if (response.status === 200) {
            this.offerOrRequestCreated = true;
            for (let i = 0; i < this.parts.length; i++) {
              const formData = new FormData();
              for (let j = 0; j < this.parts[i].pictures.length; j++) {
                formData.set(`picture_${j}`, this.parts[i].pictures[j]);
              }
              this.name = '';
              this.material = '';
              this.thickness = 0;
              this.pictures = [];
              this.counts = [1, 0, 0];
              this.note = '';
              formData.set('name', this.parts[i].name);
              formData.set('material', this.parts[i].material);
              formData.set('thickness', this.parts[i].thickness);
              formData.set('count_0', this.parts[i].counts[0]);
              formData.set('count_1', this.parts[i].counts[1]);
              formData.set('count_2', this.parts[i].counts[2]);
              formData.set('offer_id', response.data['offer']['offer_id']);
              formData.set('note', this.parts[i].note);
              await axios
                .post(`${BACKEND_URL}/upload/mobile`, formData)
                .then(response => {
                  if (response.status === 200) {
                    this.parts[i].uploaded = true;
                  }
                })
                .catch(resp => {
                  allDone = false;
                });
            }
          }
        })
        .catch(resp => {
          allDone = false;
        });
    }
  },
  computed: {
    checks() {
      let result = [];
      if (this.parts.length === 0) {
        result.push('Bitte mindestens ein Bauteil definieren!');
      }
      // if (this.deliveryType === 'Lieferung' && this.name_comp === '') {
      //   result.push('Bitte Name oder Firma definieren!');
      // }
      // if (this.deliveryType === 'Lieferung' && this.street === '') {
      //   result.push('Bitte Strasse definieren!');
      // }
      // if (this.deliveryType === 'Lieferung' && this.plz_city === '') {
      //   result.push('Bitte PLZ und Ort definieren!');
      // }
      // if (this.deliveryType === 'Lieferung' && this.phone === '') {
      //   result.push('Bitte Telefonnummer definieren!');
      // }
      return result;
    },
    requestFinalized() {
      return (
        this.offerOrRequestCreated &&
        Object.values(this.parts).every(item => item.uploaded)
      );
    },
    deliveryDateFormatted() {
      return this.formatDate(this.deliveryDate);
    }
  },
  created() {
    this.$store.dispatch('loadMaterials');
  }
};
</script>

<style scoped></style>
