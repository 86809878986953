











































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import UploadCard from '@/components/util/UploadCard.vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import CalcDXFLayerDialog from '@/components/calculator/CalcDXFLayerDialog.vue';
import CalcManual from '@/components/calculator/CalcManual.vue';

interface WrappedFile {
  progress: number;
  file: File;
}

@Component({
  components: {
    zaUpload: UploadCard,
    zaDxfDialog: CalcDXFLayerDialog,
    zaCalcManual: CalcManual
  }
})
export default class CalcFileUpload extends Vue {
  BACKEND_URL = BACKEND_URL;
  valid = false;
  currFiles: WrappedFile[] = [];
  partName = '';
  defaultMessage: string = 'Hochzuladende Dateien hier reinziehen oder mit Klick Dialog öffnen';
  acceptedFiles: string[] = ['.dxf'];

  onFileWrongExt(file: File) {}

  onFilesAdded(files: File[]) {
    for (const file of files) {
      this.uploadFile({ file: file, progress: 0 });
    }
  }

  placeholder() {}

  deleteFile(file_uuid: string) {
    this.$store.dispatch('deleteFile', file_uuid);
  }

  uploadFile(wrappedFile: WrappedFile) {
    const formData = new FormData();
    formData.set('file', wrappedFile.file);
    formData.set('offer_id', this.$store.getters.currentOfferID);
    formData.set('charge_size', this.$store.getters.chargeSize);
    this.currFiles.push(wrappedFile);
    axios
      .post(`${BACKEND_URL}/upload`, formData, {
        onUploadProgress: event => this.onUploadProgress(wrappedFile, event)
      })
      .then(value => {
        this.currFiles.splice(this.currFiles.indexOf(wrappedFile), 1);
        let uploaded_file = value.data['file'];
        let file_uuid: any = Object.keys(uploaded_file).pop();
        this.$store.commit('addFileEntry', {
          fileUUID: file_uuid,
          fileEntry: { expanded: false, ...uploaded_file[file_uuid] }
        });
        let sets = value.data['sets'];
        for (let currSet of sets) {
          this.$store.commit('addSetEntry', {
            setUUID: currSet['set_uuid'],
            setEntry: currSet
          });
        }
        let parts = value.data['parts'];
        for (let currPart of parts) {
          this.$store.commit('addPartEntry', {
            partUUID: currPart['part_uuid'],
            partEntry: currPart
          });
        }
      })
      .catch(() => {
        // this.$store.commit('deleteFile', wrappedFile.name);
      });
  }

  onUploadProgress(wrappedFile: WrappedFile, progressEvent: ProgressEvent) {
    wrappedFile.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  }

  switchExpanded(entry: any) {
    entry.expanded = !entry.expanded;
  }

  get uploadedFiles() {
    return this.$store.getters.files;
  }

  getPartsOfFileUUID(uuid: string) {
    return this.$store.getters.getPartsOfFileUUID(uuid);
  }

  nameOfPartUUID(uuid: string) {
    return this.$store.getters.getPartNameToPartUUID(uuid);
  }
}
