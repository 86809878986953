<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <slot name="buttonSlot" v-bind:on="on" v-bind:attrs="attrs"></slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Bauteil definieren</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field v-model="name" label="Bauteilname"></v-text-field>
          </v-col>
          <v-col class="d-flex justify-center" cols="12">
            <v-btn icon fab large @click="forwardClickToInput">
              <v-icon dark>
                add_a_photo
              </v-icon>
            </v-btn>
            <input
              ref="photoInput"
              type="file"
              id="imageFile"
              accept="image/*"
              style="display: none"
              @change="onFilesAdded"
              multiple
            />
          </v-col>
          <v-col cols="12">
            <v-list v-if="pictures.length > 0">
              <v-list-item v-for="(pic, index) in pictures" :key="`${pic.name}_${index}`">
                <v-list-item-content>
                  <v-list-item-title>{{ pic.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="deleteFile(index)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <div v-else class="d-flex justify-center">
              Bitte Bilder hochladen
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <material-select
              ref="matSelect"
              @informationChanged="informationChanged"
              :material="material"
              @materialChanged="material = $event"
              @thicknessChanged="thickness = $event"
              :thickness="thickness"
              :use-autocomplete="false"
            ></material-select>
          </v-col>
          <v-col cols="12">
            <div class="subheading theme--light">Losgrössen</div>
            <v-row justify="space-between">
              <v-col cols="3" v-for="(count, index) in counts" :key="index">
                <v-text-field
                  type="number"
                  class="inputLos"
                  v-model="counts[index]"
                  min="1"
                  @change="numPartsChanged"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-textarea label="Bemerkungen" rows="2" no-resize v-model="note"> </v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <v-btn @click="save" fab fixed bottom right class="green" :disabled="missingInputs">
        <v-icon>save</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import MaterialSelect from '@/components/calculator/MaterialSelect.vue';

export default {
  name: 'MobilePart',
  components: { MaterialSelect },
  data: function() {
    return {
      dialog: false,
      name: '',
      material: '',
      thickness: 0,
      pictures: [],
      counts: [1, 0, 0],
      note: ''
    };
  },
  methods: {
    resetValues() {
      this.name = '';
      this.material = '';
      this.thickness = 0;
      this.pictures = [];
      this.counts = [1, 0, 0];
      this.note = '';
      this.$refs.matSelect.resetValues();
    },
    forwardClickToInput() {
      const ref = this.$refs.photoInput;
      ref.click();
    },
    onFilesAdded(event) {
      const files = event.target.files;
      this.pictures.push(...files);
    },
    deleteFile(index) {
      this.pictures.splice(index, 1);
    },
    informationChanged({ material, thickness }) {
      this.material = material;
      this.thickness = thickness;
    },
    save() {
      this.$emit('added-part', {
        name: this.name,
        material: this.material,
        thickness: this.thickness,
        counts: this.counts,
        pictures: this.pictures,
        note: this.note,
        uploaded: false
      });
      this.resetValues();
      this.dialog = false;
    },
    numPartsChanged() {
      this.counts = this.counts.map(Number).map((value, index) => {
        if (index === 0) return Math.max(value, 1);
        else return Math.max(value, 0);
      });
    }
  },
  computed: {
    missingInputs() {
      return this.material === '' || this.thickness === 0;
    }
  },
  watch: {
    '$route.hash'(newHash, oldHash) {
      if (newHash === '#part-dialog') {
        this.dialog = true;
      } else if (oldHash === '#part-dialog') {
        this.dialog = false;
      }
    },
    dialog(newVal, oldVal) {
      if (newVal === false) {
        this.resetValues();
        if ('#part-dialog' === this.$route.hash) {
          this.$router.back();
        }
      }
    }
  }
};
</script>

<style scoped></style>
