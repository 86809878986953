import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import jwtDecode from 'jwt-decode';
import Calculator from '@/views/Calculator.vue';
import Offers from '@/views/Offers.vue';
import Mobile from '@/views/mobile/Mobile.vue';
import MobileOffer from '@/views/mobile/MobileOffer.vue';
import { isMobile } from '@/main';
import Orders from '@/views/Orders.vue';
import Order from '@/views/Order.vue';

Vue.use(Router);

function getRouter() {
  if (isMobile()) {
    return new Router({
      mode: 'history',
      base: process.env.BASE_URL,
      routes: [
        {
          path: '/',
          name: 'mobile',
          component: Mobile,
          meta: { requiresAuth: true, acc_level: 3 }
        },
        {
          path: '/offer',
          name: 'offer',
          component: MobileOffer,
          meta: { requiresAuth: true, acc_level: 3 }
        },
        {
          path: '/login',
          name: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ './views/Login.vue'),
          meta: { requiresAuth: false }
        }
      ]
    });
  } else {
    return new Router({
      mode: 'history',
      base: process.env.BASE_URL,
      routes: [
        {
          path: '/',
          name: 'offers',
          component: Offers,
          meta: { requiresAuth: true, acc_level: 3 }
        },
        {
          path: '/orders',
          name: 'orders',
          component: Orders,
          meta: { requiresAuth: true, acc_level: 3 }
        },
        {
          path: '/calc',
          name: 'calc',
          component: Calculator,
          meta: { requiresAuth: true, acc_level: 3 }
        },
        {
          path: '/order/:orderId',
          name: 'order',
          component: Order,
          props: true,
          meta: { requiresAuth: true, acc_level: 3 }
        },
        {
          path: '/login',
          name: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ './views/Login.vue'),
          meta: { requiresAuth: false }
        },
        {
          path: '/users',
          name: 'users',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ './views/Users.vue'),
          meta: { requiresAuth: true, acc_level: 2 }
        },
        {
          path: '/params',
          name: 'params',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ './views/Params.vue'),
          meta: { requiresAuth: true, acc_level: 2 }
        },
        {
          path: '/texts',
          name: 'texts',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ './views/Texts.vue'),
          meta: { requiresAuth: true, acc_level: 2 }
        },
        {
          path: '/mobile',
          name: 'mobile',
          component: Mobile,
          meta: { requiresAuth: true, acc_level: 3 }
        }
      ]
    });
  }
}

const router = getRouter();

router.beforeEach((to, from, next) => {
  // check if "to" requires authentication
  let record = to.matched.find(record => record.meta.requiresAuth);
  if (record) {
    if (store.getters.isAuthenticated) {
      // store.dispatch('updateRefreshDefaults');
      if (store.getters.userRole <= record.meta.acc_level) {
        next();
      } else {
      }
    } else {
      // try to load jwt cookie
      const jwt_header_payload = Vue.$cookies.get('jwt_header_payload');
      if (jwt_header_payload) {
        // set user and continue
        const user = jwtDecode(jwt_header_payload);
        store.commit('setUser', user);
        next();
      } else {
        // requires auth, is not authenticated and has no cookie
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        });
      }
    }
  } else {
    // requires no auth --> make sure is not auth, may change in future
    if (!store.getters.isAuthenticated) {
      next();
    }
  }
});

export default router;
