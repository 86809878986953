<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title v-if="order.order_accepted"> Bestellbestätigung {{ order.order_id }}</v-card-title>
          <v-card-title v-else>Bestellungzusammenfassung {{ order.order_id }}</v-card-title>
          <v-card-text>
            <v-container style="width: 100%">
              <v-row justify="space-around">
                <v-col cols="6" v-for="(set, index) in order.sets" :key="`set${index}`">
                  <v-card height="100%">
                    <v-container class="fill-height">
                      <v-row class="no-gutters flex-wrap flex-column fill-height">
                        <v-col>
                          <v-card-title>Set {{ index + 1 }}</v-card-title>
                        </v-col>
                        <v-col>
                          <v-card-subtitle>Teile</v-card-subtitle>
                          <v-card-text>
                            <ul>
                              <li v-for="(part_info, inner_ind) in set.parts" :key="`${index}_part_set${inner_ind}`">
                                {{ part_info.count }} Stk. {{ part_info.source_file_name }} - {{ part_info.name }}
                              </li>
                            </ul>
                          </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-subtitle v-if="set.calculation.is_verified"
                            >Kosten Total: {{ set.calculation.total_correct }} CHF</v-card-subtitle
                          >
                          <v-card-subtitle v-else>Preis folgt nach Verifizierung</v-card-subtitle>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import { BACKEND_URL } from '@/za_conf';
import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  props: ['order'],
  data() {
    let vm = this;
    return {
      BACKEND_URL: BACKEND_URL,
      offeredPrices: [],
    };
  },
  methods: {
    acceptOrder() {
      axios.post(`${BACKEND_URL}/set_order_accepted`, {
        order_id: this.order.order_id
      });
      // .then(response => {
      //   this.$store.dispatch('calculateOffer');
      // });
    },
    finishedOrder(){
      axios.post(`${BACKEND_URL}/set_order_finished`, {
        order_id: this.order.order_id
      });
    },
    validate(value, set_id) {
      axios.post(`${BACKEND_URL}/set_validate`, {
        set_id: set_id,
        value: Number(value),
      });
      // .then(response => {
      //   this.$store.dispatch('calculateOffer');
      // });
    }
  }
});
</script>
<style>
tr.tbdetails:hover {
  background-color: transparent !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: right;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  text-align: right;
}
</style>
