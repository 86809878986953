<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn v-if="order.order_accepted && !order.order_finished" class="green" @click="finishedOrder"
          >Bestellung abholbereit</v-btn
        >
        <v-btn v-else-if="!order.order_accepted && !order.order_finishedg" class="green" @click="acceptOrder"
          >Bestellung akzeptieren</v-btn
        >
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title> Bestellung {{ order.order_id }}</v-card-title>
          <v-card-text>
            <v-container style="width: 100%">
              <v-row justify="space-around">
                <v-col cols="6" v-for="(set, index) in order.sets" :key="`set${index}`">
                  <v-card height="100%" v-if="!order.order_accepted">
                    <v-container class="fill-height">
                      <v-row class="no-gutters flex-wrap flex-column fill-height">
                        <v-col>
                          <v-card-title>Set {{ index + 1 }}</v-card-title>
                        </v-col>
                        <v-col>
                          <v-card-subtitle>Teile</v-card-subtitle>
                          <v-card-text>
                            <ul>
                              <li v-for="(part_info, inner_ind) in set.parts" :key="`${index}_part_set${inner_ind}`">
                                {{ part_info.count }} Stk. {{ part_info.source_file_name }} - {{ part_info.name }}
                              </li>
                            </ul>
                          </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-subtitle>Berechnung</v-card-subtitle>
                          <v-card-text>
                            <table style="width: 100%">
                              <tr style="border:0;  padding:0;margin:0;">
                                <td style="border-top: 1px solid">
                                  <strong>Schneiden</strong>
                                </td>
                                <td class="text-right" style="border-top: 1px solid; font-weight: bold">
                                  {{ set.calculation.cutting | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  <strong>Material</strong>
                                </td>
                                <td class="text-right" style="font-weight: bold">
                                  {{ set.calculation.material | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  <strong>Mechanik</strong>
                                </td>
                                <td class="text-right" style="font-weight: bold">
                                  {{ set.calculation.mechanic | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  Bohrzeit [h:m:s] | [CHF]
                                </td>
                                <td class="text-right">
                                  {{ set.calculation.mechanic_drill[0] }} |
                                  {{ set.calculation.mechanic_drill[1] | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  <strong>Arbeit</strong>
                                </td>
                                <td class="text-right" style="font-weight: bold">
                                  {{ set.calculation.work | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  Prog.Zeit [h:m:s] | [CHF]
                                </td>
                                <td class="text-right">
                                  {{ set.calculation.work_prog[0] }} |
                                  {{ set.calculation.work_prog[1] | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  Einrichtzeit [h:m:s] | [CHF]
                                </td>
                                <td class="text-right">
                                  {{ set.calculation.work_setup[0] }} |
                                  {{ set.calculation.work_setup[1] | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  Schneidzeit [h:m:s] | [CHF]
                                </td>
                                <td class="text-right">
                                  {{ set.calculation.work_cut[0] }} |
                                  {{ set.calculation.work_cut[1] | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  Abräumzeit [h:m:s] | [CHF]
                                </td>
                                <td class="text-right">
                                  {{ set.calculation.work_clear[0] }} |
                                  {{ set.calculation.work_clear[1] | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  Entgratzeit [h:m:s] | [CHF]
                                </td>
                                <td class="text-right">
                                  {{ set.calculation.work_deburr[0] }} |
                                  {{ set.calculation.work_deburr[1] | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  Total Zeit [h:m:s]
                                </td>
                                <td class="text-right">
                                  {{ set.calculation.work_total_time }}
                                </td>
                                <td />
                              </tr>
                              <tr style="border:0;  padding:0;margin:0;">
                                <td style="border-top-style: double">
                                  <strong>Total</strong>
                                </td>
                                <td class="text-right" style="border-top-style: double; font-weight: bold">
                                  {{ set.calculation.total | formatNumber }}
                                  CHF
                                </td>
                                <td />
                              </tr>

                              <tr style="border:0;  padding:0;margin:0;">
                                <td>
                                  <strong>Total Korrektur</strong>
                                </td>
                                <td class="text-right" style="font-weight: bold">
                                  <v-text-field
                                    type="number"
                                    class="right-input"
                                    :value="set.calculation.total_correct"
                                    @change="validate($event, set.set_uuid)"
                                    suffix="CHF"
                                  ></v-text-field>
                                </td>
                                <td />
                              </tr>
                            </table>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                  <v-card height="100%" v-else>
                    <v-container class="fill-height">
                      <v-row class="no-gutters flex-wrap flex-column fill-height">
                        <v-col>
                          <v-card-title>Set {{ index + 1 }}</v-card-title>
                        </v-col>
                        <v-col>
                          <v-card-subtitle>Teile</v-card-subtitle>
                          <v-card-text>
                            <ul>
                              <li v-for="(inner_item, inner_ind) in set.parts" :key="`${index}_part_set${inner_ind}`">
                                {{ part_info.count }} Stk. {{ part_info.source_file_name }} - {{ part_info.name }}
                              </li>
                            </ul>
                          </v-card-text>
                        </v-col>
                        <v-col>
                          <v-card-subtitle
                            >Kosten Total: {{ set.calculation.total_correct | formatNumber }} CHF</v-card-subtitle
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import { BACKEND_URL } from '@/za_conf';
import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  props: ['order'],
  data() {
    let vm = this;
    return {
      BACKEND_URL: BACKEND_URL,
      offeredPrices: [],
    };
  },
  methods: {
    acceptOrder() {
      axios.post(`${BACKEND_URL}/set_order_accepted`, {
        order_id: this.order.order_id
      }).then(res => {
        this.$router.push('/orders')
      });
      // .then(response => {
      //   this.$store.dispatch('calculateOffer');
      // });
    },
    finishedOrder(){
      axios.post(`${BACKEND_URL}/set_order_finished`, {
        order_id: this.order.order_id
      }).then(res => {
        this.$router.push('/orders')
      });
    },
    validate(value, setUUID) {
      axios
      .post(`${BACKEND_URL}/set_validate`, {
        order_id: this.order.order_id,
        set_uuid: setUUID,
        value: Number(value)
      })
      .then(response => {
        this.$store.commit('addSetEntry', {
          setUUID: Object.keys(response.data).pop(),
          setEntry: Object.values(response.data).pop()
        });
      });
    },
  }
});
</script>
<style>
tr.tbdetails:hover {
  background-color: transparent !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: right;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  text-align: right;
}
</style>
