












































































































































































































































































































































import { BACKEND_URL } from '@/za_conf';
import Vue from 'vue';
import { round, formatNumber } from '@/filters';
import axios from 'axios';

export default Vue.extend({
  // type inference enabled
  data() {
    let vm = this;
    return {
      BACKEND_URL: BACKEND_URL,
      offeredPrices: [] as any[],
      expanded: [],
      dialog: false,
      dialogExport: false,
      nummerExport: ''
    };
  },
  computed: {
    headers() {
      let headers = [
        {
          text: 'Teil',
          align: 'left',
          sortable: false
        }
      ];
      for (let i = 0; i < this.maxNumberOfSetsInGroup; i++) {
        headers.push({
          text: `Losgrösse ${i + 1}`,
          align: 'right',
          sortable: false
        });
        headers.push({
          text: `Kosten Losgrösse ${i + 1}`,
          align: 'right',
          sortable: false
        });
      }

      headers.push({
        text: 'Details',
        align: 'right',
        sortable: false
      });

      return headers;
    },
    offerVerifiedOrOrdered() {
      return (
        this.$store.getters.getCurrentOfferStatus == 'Bestellt' ||
        this.$store.getters.getCurrentOfferStatus == 'Verifiziert'
      );
    },
    partsWithSets() {
      return Object.values(this.$store.getters.getSetGroups);
    },
    maxNumberOfSetsInGroup() {
      let maxNumberOfSetsInGroup: number = Math.max(
        ...Object.values(this.$store.getters.getSetGroups).map((x: any) => {
          let numSets: number = x.sets.length;
          return numSets;
        }),
        0
      );
      return maxNumberOfSetsInGroup;
    }
  },
  methods: {
    getPartByUUID(partUUID: string) {
      return this.$store.getters.parts[partUUID];
    },
    getPartNameByUUID(partUUID: string) {
      let fileName = this.$store.getters.parts[partUUID].source_file_name;
      let partName = this.$store.getters.parts[partUUID].features.name;
      return `${fileName} - ${partName}`;
    },
    getSetByUUID(uuid: string) {
      return this.$store.getters.sets[uuid];
    },
    getCountOfSetForPart(partUUID: string, setUUID: string) {
      let currSet = this.$store.getters.sets[setUUID];
      for (let entry of currSet.parts) {
        if (entry.part_uuid == partUUID) {
          return entry.count;
        }
      }
      return -1;
    },
    validate(value: number, setUUID: number): any {
      axios
        .post(`${BACKEND_URL}/set_validate`, {
          offer_id: this.$store.getters.currentOfferID,
          set_uuid: setUUID,
          value: Number(value)
        })
        .then(response => {
          this.$store.commit('addSetEntry', {
            setUUID: Object.keys(response.data).pop(),
            setEntry: Object.values(response.data).pop()
          });
        });
    },
    verify() {
      this.dialog = false;
      axios
        .post(`${BACKEND_URL}/verify_offer`, {
          offer_id: this.$store.getters.currentOfferID
        })
        .then(res => {
          this.$router.push('/');
        });
    },
    exportOffer() {
      axios
        .post(`${BACKEND_URL}/export_offer`, {
          offer_id: this.$store.getters.currentOfferID
        })
        .then(res => {
          this.dialogExport = true;
          this.nummerExport = res.data['export_number'];
        });
    }
  }
});
