<template>
  <v-data-table :headers="headers" :items="getOffers" sort-by="offer_id" sort-desc>
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Offerten-Übersicht</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="green" dark class="mb-2" @click="newOffer">Neue Offerte </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <!--      <v-icon small class="mr-2" @click="pdf(item.offer_id)">-->
      <!--        picture_as_pdf-->
      <!--      </v-icon>-->
      <v-icon
        v-if="!item.status.includes('Mobile') && (hasManufacturerRights || item.status !== 'Bestellt')"
        small
        class="mr-2"
        @click="editItem(item.offer_id, item.status)"
      >
        edit
      </v-icon>
      <v-icon small class="mr-2" @click="download(item.offer_id)">
        cloud_download
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="deleteItem(item.offer_id)"
        v-if="item.status === 'Entwurf' || item.status === 'Bestellt' || hasManufacturerRights"
      >
        delete
      </v-icon>
    </template>
    <template v-slot:item.instant_order="{ item }">
      <!--      <v-icon small class="mr-2" @click="pdf(item.offer_id)">-->
      <!--        picture_as_pdf-->
      <!--      </v-icon>-->
      <div class="d-flex justify-center align-center">
        <orderdialog :offerId="item.offer_id" v-if="item.status === 'Verifiziert'">
          <template v-slot:default="{ action }">
            <v-btn @click="action" class="green" text>
              <v-icon>shopping_cart</v-icon>
              Bestellen
            </v-btn>
          </template>
        </orderdialog>
      </div>
    </template>
  </v-data-table>
</template>

<script lang="js">
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import { formatOfferId } from '@/store';
import CalcOrderDialog from '@/components/calculator/CalcOrderDialog.vue';

export default Vue.extend({
  components: {
    orderdialog: CalcOrderDialog
  },
  data: () => ({
    offers: [],
    headers: [
      {
        text: 'Nummer',
        align: 'left',
        sortable: false,
        value: 'offer_id'
      },
      { text: 'Name', value: 'name' },
      { text: 'Benutzer', value: 'user' },
      { text: 'Datum', value: 'date' },
      { text: 'Status', value: 'status' },
      { text: '', value: 'instant_order', sortable: false, align: 'center' },
      { text: 'Aktionen', value: 'action', sortable: false }
    ]
  }),
  created() {
    this.retrieveOffers();
  },
  computed: {
    getOffers() {
      let offers = this.offers.filter((offer) => !offer.status.startsWith('Trainer'));

      const options = {
        day: 'numeric',
        year: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric'
      };
      for (let o of offers) {
        o.date = new Date(o.date).toLocaleDateString('de-CH', options);
      }
      return offers;
    },

    hasManufacturerRights() {
      return this.$store.getters.hasManufacturerRights;
    }
  },
  methods: {
    pdf(offerId) {
      axios
        .post(`${BACKEND_URL}/pdf`, { offer_id: offerId }, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${offerId}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {});
    },
    deleteItem(offerId) {
      let ref = this;
      axios
        .post(`${BACKEND_URL}/delete_offer`, { offer_id: offerId })
        .then(_ => {
          ref.retrieveOffers();
        })
        .catch(error => {});
    },
    editItem(offerId) {
      this.$store.dispatch('loadOffer', offerId);
    },
    download(offerId) {
      axios.post(`${BACKEND_URL}/download_parts`, { offer_id: offerId }, { responseType: 'blob' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${offerId}.zip`);
        document.body.appendChild(link);
        link.click();
      });
    },
    retrieveOffers() {
      axios
        .get(`${BACKEND_URL}/offers`)
        .then(response => {
          if (response.status === 200) {
            this.offers = response.data;
          }
        })
        .catch(error => {});
    },
    newOffer() {
      this.$store.commit('resetOffer');
      this.$router.push('/calc');
    }
  }
});
</script>
