<template>
  <calc-order-manufacturer
    v-if="order && hasManufacturerRights"
    :order="this.order"
  ></calc-order-manufacturer>
  <calc-order-customer
    v-else-if="order"
    :order="this.order"
  ></calc-order-customer>
  <div v-else>Lädt Bestellung....</div>
</template>

<script lang="js">
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import CalcOrderCustomer from "@/components/calculator/CalcOrderCustomer";
import CalcOrderManufacturer from "@/components/calculator/CalcOrderManufacturer";

export default Vue.extend({
  components: { CalcOrderManufacturer, CalcOrderCustomer },
  data: () => ({
    order: null,
    orderVerified: true
  }),
  props: ['orderId'],
  computed: {
    getOrders() {
      return this.orders;
    },

    hasManufacturerRights() {
      return this.$store.getters.hasManufacturerRights;
    }
  },
  methods: {
  },
  mounted() {
    axios
    .post(`${BACKEND_URL}/order`, {
      order_id: this.orderId
    })
    .then(res => {
      this.order = res.data;
    });
  }
});
</script>
