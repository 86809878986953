<template>
  <v-container class="mr-0 pr-0">
    <v-row no-gutters>
      <v-col>
        <slot :action="activatorPressed"></slot>
        <v-dialog v-model="dialog" :width="dialog_width">
          <v-card>
            <v-card-title> Bitte Set auswählen:</v-card-title>
            <v-card-text>
              <v-container style="width: 100%">
                <v-row justify="space-around">
                  <v-col cols="4" v-for="(set, index) in sets" :key="`set${index}`">
                    <v-card height="100%" :class="isSelectedStyle(index)">
                      <v-container class="fill-height">
                        <v-row class="no-gutters flex-wrap flex-column fill-height">
                          <v-col>
                            <v-card-title>Set {{ index + 1 }}</v-card-title>
                          </v-col>
                          <v-col>
                            <v-card-text>
                              <ul>
                                <li v-for="(part_info, inner_ind) in set.parts" :key="`${index}_part_set${inner_ind}`">
                                  {{ part_info.count }} Stk. {{ part_info.source_file_name }} - {{ part_info.name }}
                                </li>
                              </ul>
                            </v-card-text>
                          </v-col>
                          <v-col>
                            <v-card-text v-if="set.calculation.is_verified">
                              <strong> Preis: CHF {{ set.calculation.total_correct }} </strong>
                            </v-card-text>
                            <v-card-text v-else>
                              <strong>
                                Preis folgt nach manueller Prüfung
                              </strong>
                            </v-card-text>
                          </v-col>
                          <v-col class="grow align-end justify-end">
                            <v-card-actions>
                              <v-btn @click="changeSelection(index, set.set_uuid)">
                                Auswahl
                              </v-btn>
                            </v-card-actions>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="justify-center align-center">
              <v-btn :disabled="selection_inds.length === 0" @click="createOrder">Bestellung absenden</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';

export default Vue.extend({
  data() {
    return {
      dialog: false,
      selection_inds: [],
      selected_set_uuids: [],
      sets: []
    };
  },
  watch: {
    dialog: function(val) {
      if (!val) {
        this.selection_inds = [];
        this.selected_set_uuids = [];
      }
    }
  },
  props: {
    offerId: Number,
    dialog_width: {type: [Number, String], default: '80%'}
  },
  methods: {
    activatorPressed(event) {
      event.stopPropagation();
      this.dialog = true;
      axios
        .post(`${BACKEND_URL}/sets`, {
          offer_id: this.offerId
        })
        .then(res => {
          this.sets = res.data.sets;
        });
    },
    isSelectedStyle(index) {
      if (this.selection_inds.indexOf(index) >= 0) {
        return 'green lighten-2';
      }
      return '';
    },
    changeSelection(index, set_uuid) {
      let sel_index = this.selection_inds.indexOf(index);
      let set_index = this.selected_set_uuids.indexOf(set_uuid);
      if (sel_index >= 0) {
        this.selection_inds.splice(sel_index, 1);
        this.selected_set_uuids.splice(set_index, 1);
      } else {
        this.selection_inds.push(index)
        this.selected_set_uuids.push(set_uuid)
      }
    },
    createOrder() {
      axios
        .post(`${BACKEND_URL}/create_order`, {
          offer_id: this.offerId,
          set_uuids: this.selected_set_uuids
        })
        .then(res => {
          this.$store.commit('resetOffer');
          this.$router.push({ path: '/orders' });
        });
    }
  },
  mounted() {}
});
</script>

<style scoped></style>
