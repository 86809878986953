<template>
  <v-container>
    <v-row justify="space-around" align="center">
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-h5">
            Neue Anfrage
          </v-card-title>
          <v-card-subtitle
            >Schnell eine neue Anfrage erstellen mit Fotos der gewünschten
            Bauteilen</v-card-subtitle
          >
          <v-card-actions>
            <v-btn text outlined @click="$router.push({ name: 'offer' })">
              Neue Anfrage
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-h5">
            Verlauf
          </v-card-title>
          <v-card-subtitle
            >Bisher erstellte Anfragen und Bestellungen sind in der
            Desktop-Version ersichtlich</v-card-subtitle
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';

export default {
  name: 'Mobile',
  data: () => ({}),

  computed: {}
};
</script>

<style scoped></style>
