






























import { Component, Prop, Vue } from 'vue-property-decorator';
import CalcShippingItem from '@/components/calculator/CalcShippingItem.vue';

@Component({
  components: {
    zaCalcShippingItem: CalcShippingItem
  }
})
export default class CalcShipping extends Vue {
  @Prop({ type: Number, required: true })
  shipping!: number;

  selected_option = -1;

  cards = [
    {
      title: 'Express',
      duration: '24 Stunden',
      // savings: '',
      selected: false,
      cols: 3
    },
    {
      title: 'Standard',
      duration: '3 - 5 Arbeitstage',
      // savings: '0% Rabatt',
      selected: false,
      cols: 3
    },
    // {
    //   title: 'Economy',
    //   duration: '10 - 15 Arbeitstage',
    //   // savings: '10% Rabatt',
    //   selected: false
    // }
    // {
    //   title: 'Economy Plus',
    //   duration: '15-30 Arbeitstage',
    //   // savings: '15% Rabatt',
    //   selected: false
    // }
  ];

  selectOption(index: any) {
    this.selected_option = index;
    this.$store.dispatch('updateOfferShipping', {
      shipping: this.selected_option
    });
  }

  isSelected(index: any) {
    return index === this.selected_option ? 'green' : 'primary';
  }

  mounted() {
    this.selected_option = this.shipping;
  }
}
