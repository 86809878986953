








import { Component, Prop, Vue } from 'vue-property-decorator';
import CalcPropertiesItem from '@/components/calculator/CalcPropertiesItem.vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import * as THREE from 'three';
import STLLoader from 'three-stl-net-loader';
import { CustomerPartEntry } from '@/customer/part_details';

//let THREE = require('three');
let OrbitControls = require('three-orbitcontrols');
//let STLLoader = require('three-stl-net-loader')(THREE);
@Component({
  components: {
    zaCalcPropItem: CalcPropertiesItem
  }
})
export default class CalcProperties extends Vue {
  // rendering related
  stlLoader: any;
  sceneElements: any = {};
  canvas: HTMLCanvasElement;
  renderer: any;
  animationFrameId: number = 0;
  /////////////////////////////////////////////////////////////
  constructor() {
    super();
    this.canvas = document.createElement('canvas');
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
      antialias: true
    });
    this.renderer.setScissorTest(true);
    this.stlLoader = new STLLoader();
    this.stlLoader.setRequestHeader({ Authorization: 'Bearer token' });
    this.stlLoader.setWithCredentials(true);
  }
  get parts() {
    return Object.values(this.$store.getters.parts);
  }
  itemCreated(part: CustomerPartEntry, elem: Element) {
    cancelAnimationFrame(this.animationFrameId);
    const stlName = part.uuid;
    const sceneInitFunction = this.createSceneInitFunction(stlName);
    const sceneRenderFunction = sceneInitFunction(elem);
    this.addScene(elem, sceneRenderFunction, part.uuid);
    this.animationFrameId = requestAnimationFrame(this.renderfn);
  }
  itemDeleted(part: CustomerPartEntry, elem: Element) {
    delete this.sceneElements[part.uuid];
  }

  makeScene(elem: any) {
    const scene = new THREE.Scene();
    const fov = 50;
    const aspect = 1; // the canvas default
    const near = 0.1;
    const far = 20;
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.set(0, 1, 2.5);
    camera.lookAt(0, 0, 0);
    scene.add(camera);
    const controls = new OrbitControls(camera, elem);
    controls.enableZoom = true;
    controls.enablePan = false;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 1;
    controls.rotateSpeed = 0.6;
    {
      const color = 0xffffff;
      const intensity = 1;
      const light = new THREE.DirectionalLight(color, intensity);
      const light2 = new THREE.DirectionalLight(color, intensity);
      const light3 = new THREE.DirectionalLight(color, intensity);
      light.position.set(-1, 2, 4);
      light2.position.set(-1, 2, -4);
      light3.position.set(5, 0, 4);
      scene.add(light);
      scene.add(light2);
      scene.add(light3);
    }
    return { scene, camera, controls };
  }
  addScene(elem: Element, fn: Function, name: string) {
    const ctx = document.createElement('canvas').getContext('2d');
    if (ctx !== null) {
      elem.appendChild(ctx.canvas);
    }
    let screenShotCreated = false;
    this.sceneElements[name] = { elem, ctx, fn, screenShotCreated };
  }
  createSceneInitFunction(stlFile: string) {
    let sceneInitFunction = (elem: Element) => {
      const { scene, camera, controls } = this.makeScene(elem);
      let mesh: any;
      this.stlLoader.load(`${BACKEND_URL}/stl/` + stlFile, function(geometry: any) {
        geometry.center();
        var material = new THREE.MeshLambertMaterial({ color: 0x009999 });
        mesh = new THREE.Mesh(geometry, material);
        let sizes: number[] = Object.values(geometry.boundingBox.max);
        sizes = sizes.concat(Object.values(geometry.boundingBox.min));
        let max_size = Math.max.apply(null, sizes.map(Math.abs));
        let scaling = 1 / max_size;
        mesh.scale.set(scaling, scaling, scaling);
        mesh.position.set(0, 0, 0);
        mesh.rotation.set(-Math.PI / 2, 0, 0);
        mesh.castShadow = false;
        mesh.receiveShadow = false;
        scene.add(mesh);
      });
      return (rect: any, screenShotCreated: boolean, key: string, ctx: any, width: number, height: number) => {
        camera.aspect = rect.width / rect.height;
        camera.updateProjectionMatrix();
        controls.update();
        this.renderer.render(scene, camera);
        ctx.globalCompositeOperation = 'copy';
        ctx.drawImage(
          this.renderer.domElement,
          0,
          this.renderer.domElement.height - height,
          width,
          height, // src rect
          0,
          0,
          width,
          height
        ); // dst rect
        if (!screenShotCreated) {
          if (
            !ctx.canvas
              .getContext('2d')
              .getImageData(0, 0, ctx.canvas.width, ctx.canvas.height)
              .data.some((channel: number) => channel !== 0)
          ) {
            return;
          }
          this.sceneElements[key].screenShotCreated = true;
        }
      };
    };
    return sceneInitFunction;
  }
  renderfn() {
    let count = 0;
    for (const key of Object.keys(this.sceneElements)) {
      const { elem, fn, ctx, screenShotCreated } = this.sceneElements[key];
      // get the viewport relative position opf this element
      const rect = elem.getBoundingClientRect();
      const { left, right, top, bottom, width, height } = rect;
      const rendererCanvas = this.renderer.domElement;
      const isOffscreen =
        elem.offsetParent === null || bottom < 0 || top > window.innerHeight || right < 0 || left > window.innerWidth;
      if (!isOffscreen) {
        count += 1;
        // make sure the renderer's canvas is big enough
        if (rendererCanvas.width < width || rendererCanvas.height < height) {
          this.renderer.setSize(width, height, false);
        }
        // make sure the canvas for this area is the same size as the area
        if (ctx.canvas.width !== width || ctx.canvas.height !== height) {
          ctx.canvas.width = width;
          ctx.canvas.height = height;
        }
        this.renderer.setScissor(0, 0, width, height);
        this.renderer.setViewport(0, 0, width, height);
        fn(rect, screenShotCreated, key, ctx, width, height);
        // copy the rendered scene to this element's canvas
        // TODO i have removed copy, maybe redo?
      }
    }
    this.animationFrameId = requestAnimationFrame(this.renderfn);
  }
  updated() {}

  created() {
    this.$store.dispatch('loadMaterials');
    this.$store.dispatch('loadDrills');
  }
}
