

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class MaterialSelect extends Vue {
  @Prop({ type: String, required: true, default: '' })
  material?: any;

  @Prop({ type: Number, required: true, default: 0 })
  thickness!: number;

  @Prop({ type: Boolean, required: false, default: true })
  useAutocomplete!: boolean;

  thickness_selected: any = this.thickness;
  material_selected: any = this.material;

  materialChanged(material_name: string) {
    this.material_selected = material_name;
    this.$emit('materialChanged', this.material_selected);
  }

  async thicknessChanged(thickness: number) {
    this.thickness_selected = thickness;
    if (this.processedMaterials.indexOf(this.material_selected) < 0) {
      this.material_selected = '';
      this.$emit('materialChanged', this.material_selected);
      await new Promise(resolve => setTimeout(resolve, 500));
    }
    this.$emit('thicknessChanged', this.thickness_selected);
  }

  get processedMaterials() {
    return this.$store.getters.processedMaterials[this.thickness_selected.toFixed(1)];
  }

  thicknessStyle() {
    if (this.thickness_selected == 0 || this.thickness_selected == '') {
      return 'background-color: orange';
    } else {
      return '';
    }
  }

  materialStyle() {
    if (this.material_selected == 0 || this.material_selected == '') {
      return 'background-color: orange';
    } else {
      return '';
    }
  }

  get thicknesses() {
    return Object.keys(this.$store.getters.processedMaterials).map(Number);
  }

  resetValues() {
    this.material_selected = '';
    this.thickness_selected = 0;
  }

  mounted() {}
}
