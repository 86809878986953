



























































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CalcDrill extends Vue {
  @Prop({ type: Array, default: [] })
  drillsProp: any;

  drillNum = 1;
  drillDiameter = null;
  drillThrough = true;
  drillSink = false;

  valid = true;
  adding = false;

  initData() {
    this.drillDiameter = null;
    this.drillNum = 1;
    this.drillThrough = true;
    this.drillSink = false;

    this.valid = true;
  }

  reset() {
    this.initData();
    this.form.resetValidation();
    this.form.reset();
  }

  get form(): Vue & {
    validate: () => boolean;
    reset: () => boolean;
    resetValidation: () => boolean;
  } {
    return this.$refs.form as Vue & {
      validate: () => boolean;
      reset: () => boolean;
      resetValidation: () => boolean;
    };
  }

  addBend() {
    if (this.form.validate()) {
      let payload = {
        drillNum: this.drillNum,
        drillDiameter: this.drillDiameter,
        drillThrough: this.drillThrough,
        drillSink: this.drillSink
      };
      this.$emit('add-drill', payload);
      this.reset();
      this.adding = false;
    }
  }

  deleteBend(index: any) {
    this.$emit('delete-drill', index);
  }
}
