import { formatNumber, round } from '@/filters';
import { DisplayProperty, PartEntry } from '@/datatypes/part';

export class CountersinkInfo {
  on_request: boolean = false;
  countersink_diameter: string = '';
}

export class ThicknessInfo {
  on_request: boolean = false;
  thickness: number = 0;
}

export class ThreadInfo {
  on_request: boolean = false;
  thread_size: string = '';
}

interface PartDetails {
  rectangular_length: number;
  rectangular_width: number;
  inner_geometries: number;
}

interface OfferPartDetails {
  material: string;
  thickness: number;
  shipping: string;
  note: string;
  drills: [];
}

export class CustomerPartEntry extends PartEntry
  implements PartDetails, OfferPartDetails {
  rectangular_length: number = 0;
  rectangular_width: number = 0;
  inner_geometries: number = 0;

  // offer_part_detail
  material: string = '';
  thickness: number = 0;
  shipping: string = '';
  note: string = '';
  drills: [] = [];

  constructor() {
    super();
  }

  displayProperties(): DisplayProperty[] {
    return [
      {
        displayName: 'Länge',
        displayValue: formatNumber(round(this.rectangular_length, 2)),
        displaySuffix: 'mm'
      },
      {
        displayName: 'Breite',
        displayValue: formatNumber(round(this.rectangular_width, 2)),
        displaySuffix: 'mm'
      },
      {
        displayName: 'Innere Geometrien',
        displayValue: formatNumber(this.inner_geometries),
        displaySuffix: undefined
      }
    ];
  }

  protected createPartDetailObject(): Object {
    return {
      material: this.material,
      thickness: this.thickness,
      shipping: this.shipping,
      note: this.note,
      drills: this.drills
    };
  }

  setFieldsFromDetailsObject(part_details: PartDetails): void {
    this.rectangular_length = part_details.rectangular_length;
    this.rectangular_width = part_details.rectangular_width;
    this.inner_geometries = part_details.inner_geometries;
  }

  setFieldFromPositionDetailsObject(position_details: OfferPartDetails): void {
    // position_part_detail
    this.material = position_details.material;
    this.thickness = position_details.thickness;
    this.shipping = position_details.shipping;
    this.note = position_details.note;
    this.drills = position_details.drills;
  }
}

export function createTableDataFromCalcResponse(data: Object): Object {
  return {};
}

export function createNewPartEntry(part_details: Object) {
  let customerPartEntry: any = new CustomerPartEntry();
  for (let p in part_details) {
    if (customerPartEntry.hasOwnProperty(p)) {
      customerPartEntry[p] = 'd';
    }
  }
  return new CustomerPartEntry();
}
