

















































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';

export default Vue.extend({
  data: () => ({
    orders: [],
    headers: [
      {
        text: 'Nummer',
        align: 'left',
        sortable: false,
        value: 'order_id'
      },
      { text: 'Name', value: 'name' },
      { text: 'Benutzer', value: 'user' },
      { text: 'Datum', value: 'date' },
      { text: 'Status', value: 'status' },
      { text: 'Aktionen', value: 'action', sortable: false }
    ]
  }),
  created() {
    this.retrieveOrders();
  },
  computed: {
    getOrders() {
      return this.orders;
    },

    hasManufacturerRights() {
      return this.$store.getters.hasManufacturerRights;
    }
  },
  methods: {
    editItem(orderId: any) {
      this.$router.push({ name: 'order', params: { orderId: orderId } });
    },
    deleteItem(orderId: number) {
      axios
        .post(`${BACKEND_URL}/delete_order`, { order_id: orderId })
        .then(response => {
          this.retrieveOrders();
        })
        .catch(error => {});
    },
    download(orderId: number) {
      axios
        .post(
          `${BACKEND_URL}/download_parts`,
          { order_id: orderId },
          { responseType: 'blob' }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${orderId}.zip`);
          document.body.appendChild(link);
          link.click();
        });
    },
    retrieveOrders() {
      axios
        .get(`${BACKEND_URL}/orders`)
        .then(response => {
          if (response.status === 200) {
            this.orders = response.data;
          }
        })
        .catch(error => {});
    }
  }
});
